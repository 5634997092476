import axios from 'axios';
import moment from 'moment';
import { getRoute, sessionSetItem } from './utils';
import qs from 'qs';

export const TOKEN_STORE_KEY = 'imba-tokens';

const configs = process.env || {};
export const getConfigValue = (key, def) => {
  if (configs?.[`REACT_APP_${key}`]) return configs?.[`REACT_APP_${key}`];
  return def;
};

export const getAPIHost = () => {
  return getConfigValue('API_HOST', 'https://dev.api.imba.ac.kr/');
};

export const restApi = axios.create({
  baseURL: `${getAPIHost()}api`,
  paramsSerializer: {
    serialize: (paramObj) => {
      return qs.stringify(paramObj, { arrayFormat: 'repeat' });
    },
  },
});

export const setToken = (data) => {
  const { access_token, expires_in, refresh_token, uuid } = data || {};
  if (!!access_token) {
    const access_expire_at = data.access_expire_at ?? moment().add(expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    const refresh_expire_at = data.refresh_expire_at ?? moment().add(864000, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    const login_uuid = uuid ?? JSON.parse(sessionStorage.getItem(TOKEN_STORE_KEY)).login_uuid;


    restApi.defaults.headers.common.authorization = `Bearer ${access_token}`;
    restApi.defaults.headers.common.uuid = login_uuid;
    restApi.store_data = {
      access_token,
      access_expire_at: access_expire_at,
      refresh_token,
      refresh_expire_at: refresh_expire_at,
      login_uuid: login_uuid,
    };

    if (typeof window !== 'undefined' && window?.sessionStorage) {
      sessionSetItem(
        TOKEN_STORE_KEY,
        JSON.stringify({
          access_token,
          access_expire_at: access_expire_at,
          refresh_token,
          refresh_expire_at: refresh_expire_at,
          login_uuid: login_uuid,
        }),
      );
    }
  } else {
    delete restApi.defaults.headers.common.authorization;
    restApi.store_data = {};
    if (typeof window !== 'undefined' && window?.sessionStorage) {
      sessionSetItem(TOKEN_STORE_KEY, '{}');
    }
  }
  return data;
};

const tokenLoader = {
  isLoading: false,
  listeners: [],
};

export const getToken = () => {
  const promise = new Promise((resolve, reject) => {
    tokenLoader.listeners.push({ resolve, reject });
  });
  if (!tokenLoader.isLoading) {
    tokenLoader.isLoading = true;
    const loadData = async () => {
      let token = restApi.store_data;
      if (token.access_expire_at && moment(token.access_expire_at).add(-1, 'second').isBefore(moment())) {
        try {
          const payload = new FormData();
          payload.append('refresh_token', token.refresh_token);
          payload.append('grant_type', 'refresh_token');
          const { data } = await authApi.post(`/oauth/token`, payload);
          token = setToken(data);
        } catch (e) {
          console.warn(e);
          console.warn(e?.response?.data);
          throw e;
        }
      }
      return token;
    };
    loadData()
      .then((r) => {
        tokenLoader.listeners.forEach((listener) => listener.resolve(r));
      })
      .catch((e) => {
        tokenLoader.listeners.forEach((listener) => listener.reject(e));
        // window.location.replace('/');
      })
      .then(() => {
        tokenLoader.listeners = [];
        tokenLoader.isLoading = false;
      });
  }
  return promise;
};

restApi.interceptors.request.use(async (axiosRequestConfig) => {
  const raw = window?.sessionStorage?.getItem(TOKEN_STORE_KEY);
  const token = restApi.store_data ?? JSON.parse(raw || '{}');
  if (token.access_expire_at && moment(token.access_expire_at).diff(moment(), 'millisecond') <= 0) {
    try {
      const data = await getToken();
      axiosRequestConfig.headers.authorization = `Bearer ${data.access_token}`;
    } catch (e) {
      console.warn(token);
      console.warn(e);
      console.warn(e?.response?.data);
    }
  }
  return axiosRequestConfig;
});

restApi.interceptors.response.use(
  async (e) => {
    const { url, method } = e.config;
    if (restApi?.principal && method === 'get' && url.includes('/xlsx')) {
      const data = getRoute(restApi?.principal);
      if (data) {
        await restApi.post(`/account-histories`, data);
      }
    }

    // const sendData = async () => {
    //   await restApi.post(`/account-histories`, {
    //     name: route?.name,
    //     path: location.pathname,
    //     mobile: window.innerWidth < 1023,
    //   });
    // };
    // sendData().catch(console.warn);

    return e;
  },
  async (error) => {
    if (['invalid_token', 'unauthorized'].includes(error?.response?.data?.error)) {
      console.warn(
        'invalid_token',
        moment().format('YYYY-MM-DD HH:mm:ss'),
        error?.response?.config?.headers?.authorization,
      );
      await setToken(null, !!window, true);
    }
    if (['로그인 만료'].includes(error?.response?.data.message ?? error?.response?.data.error_description)) {
      console.warn(
        'invalid_token',
        moment().format('YYYY-MM-DD HH:mm:ss'),
        error?.response?.config?.headers?.authorization,
      );
      await setToken(null, !!window, true);
      window.location.reload();
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export const getFilesHost = () => {
  return `${getAPIHost()}api/files`;
};

export const authApi = axios.create({
  baseURL: getAPIHost(),
  auth: {
    username: 'skkuimba',
    password: 'skkuimba!@',
  },
});

export default {
  TOKEN_STORE_KEY,
  getAPIHost,
  restApi,
  authApi,
  setToken,
};
